.react-tel-input .form-control {
  width: inherit !important;
}

.vi__character {
  line-height: 40px;
  font-size: 20px;
  width: 40px !important;
  flex-grow: 0;
  flex-basis: initial;
  color: transparent;
}

.vi__character--filled {
  color: #494949;
}
