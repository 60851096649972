.container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  /* border:1px black solid; */
  width: 100%;
}

.container > * {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.container img {
  width: 45px;
  height: 45px;
  margin-right: 15px;
}

.top {
  flex: 4;
  border-bottom: lightgrey 1px solid;
  flex-direction: row;
  justify-content: flex-start;
  font-family: "Avenir Next Cyr", sans-serif !important;
  font-size: 22px;
}

.bottom {
  flex: 6;
  color: white;
  font-family: "Inter", sans-serif !important;
}

.colorBox {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  width: 100%;
  align-items: center;
  height: 55px;
}

.colorBox > * {
  height: 100%;
  align-items: center;
  display: flex;
}
.index {
  font-size: 2.3vh;
  width: 50px;
  justify-content: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.desc {
  font-size: 2.2vh;
  padding-left: 15px;
  width: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@media (max-width: 426px) {
  .desc {
    font-size: 1.9vh;
  }

  .index {
    font-size: 2.1vh;
  }

  .container img {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 400px) {
  .container > * {
    padding: 8px;
  }
  .top {
    font-size: 18px;
  }
  .colorBox {
    height: 44px;
  }
}

@media (max-width: 351px) {
  .container > * {
    padding: 8px;
  }
  .top {
    font-size: 13px;
  }
  .colorBox {
    height: 39px;
  }
  .desc {
    padding-left: 10px;
  }
}

@media (min-height: 2400px) {
  .container img {
    width: 120px;
    height: 120px;
    margin-right: 15px;
  }

  .colorBox {
    height: 70% !important;
  }
  .desc {
    padding-left: 30px;
    font-size: 1.8vh;
  }
  .index {
    width: 15%;
    font-size: 1.8vh;
  }
  .top {
    font-size: 1.8vh !important;
    padding-left: 20px;
  }
}

@media (min-height: 1599px) {
  .colorBox {
    height: 70% !important;
  }
  .desc {
    font-size: 1.8vh;
  }
  .index {
    font-size: 1.8vh;
  }
  .top {
    font-size: 24px;
  }
}

@media (max-height: 600px) {
  .index {
    font-size: 4vh !important;
  }
  .desc {
    font-size: 4vh;
  }
}

@media (max-height: 400px) {
  .index {
    font-size: 5vh !important;
  }
  .desc {
    font-size: 5vh;
  }
}

@media (max-height: 350px) {
  .index {
    font-size: 6vh !important;
  }
  .desc {
    font-size: 6vh;
  }
}
