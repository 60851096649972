.loader {
    width: 122px;
    height: 122px;
    -webkit-animation: spin 3.5s linear infinite;
    animation: spin 3.5s linear infinite;
   background-image: url(spin.png);
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .s{
    width: 122px;
    height: 122px;
    background-image: url(s.png);
    position: absolute;
    top: 0;
    z-index: -1;
  }
  
  .inner
  {
  width: 122px;
  height: 122px;
  position: relative
  }
  
  body
  {
  margin:0
  }
  
  .outer
  {
      display: flex;
      width: 100%;
      height: 100vh;
      justify-content: center;
      align-items: center;
      background: white!important;
      z-index: 1;
      position: relative
  }

  .footer
  {
    position: absolute;
    bottom: 25px;
    font-size: 19px;
    color: #3336ED

  }