/* AdminPage.css */

.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th,
.user-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.user-table th {
  background-color: #f2f2f2;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 315px;
  height: 280px;
}

.close-button {
  position: absolute;
  top: 0px;
  font-size: 16px;
  right: 10px;
  cursor: pointer;
}

.popup-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.page-content {
  width: 96%;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  display: "flex";
}

.header-title {
  font-size: 14px;
  font-weight: 700;
}

.header-subtitle {
  margin-top: 10;
  font-size: 13px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
