.App {
  /* padding: 0 18px 0 18px; */
  height: 100vh;
}

#root {
  /* max-height: 630px; */
}

@media (min-width: 666px) {
  #root {
    /* width: 430px;
    margin-left: auto;
    margin-right: auto; */
    border: 0.5px lightgrey solid;
  }
}

@media (max-height: 630px) {
  #root {
    overflow-y: auto;
  }
}

body {
  overflow-y: hidden;
  overflow: hidden;
  max-height: 100vh;
  font-size: 1.4vh !important;
  font-family: "Avenir Next Cyr Medium", sans-serif !important;
}

div[class^="screensaver-"] {
  width: 100vw !important;
  height: 100vh !important;
}

.screensaver {
  width: 0;
  height: 0;
  color: white;
  font-size: 30px;
  justify-content: center;
  align-items: center;
}

.screensaver-enter {
  opacity: 0;
  z-index: 2;
  width: 100vw !important;
  height: 100vh !important;
}

.screensaver-enter-active {
  opacity: 1;
  transition: opacity 500ms;
  z-index: 2;
  width: 100vw !important;
  height: 100% !important;
}

.screensaver-enter-done {
  z-index: 2;
  width: 100vw !important;
  height: 100% !important;
}

.screensaver-exit-done {
  opacity: 0;
  width: 0 !important;
  height: 0 !important;
}

.screensaver-exit-active {
  opacity: 0;
  transition: opacity 500ms;
  z-index: 1;
  width: 100vw !important;
  height: 100% !important;
}

.chartLoading {
  display: flex;
  z-index: 1;
  width: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  left: -10px;
  top: -10px;
  position: absolute;
}
.chartLoading .MuiCircularProgress-svg {
  color: grey;
}

.Wrapper__text {
  width: 100%;
  height: 17px;
  align-items: center;
  display: flex;
  font-size: 11px;
}

@media (min-width: 1081px) {
  .App {
    margin-left: auto;
    margin-right: auto;
    border: 0.5px lightgrey solid;
  }
}

.react-any-slider-dots__dot {
  background: #b1c8ad !important;

  height: 10px !important;
  width: 10px !important;
  opacity: unset !important;
}

.react-any-slider-dots__dot--active {
  background: #788ae6 !important;
}

.react-any-slider-dots {
  /* margin: 4px 0 8px 0 */
}

@media (min-width: 800px) and (max-height: 900px) {
  .react-any-slider-dots {
    opacity: 0;
  }
}

.react-any-slider-dots {
  bottom: 0 !important;
  position: relative !important;
  justify-content: center;
  display: flex;
}

.Wrapper__done_text {
  font-size: 10px;
}

@media (max-width: 708px) {
  .Wrapper__text {
    font-size: 10px;
  }
}

@media (max-width: 436px) {
  .Wrapper__text {
    font-size: 8px;
  }
  .Wrapper__done_text {
    font-size: 8px;
  }
}

@media (max-width: 401px) {
  .Wrapper__text {
    font-size: 7px;
    height: 22px;
  }
  .Wrapper__done_text {
    font-size: 7px;
  }
}
