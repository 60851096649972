/* .modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
} */

.modal-image {
  max-height: 80%;
  max-width: 80%;
}

.modal-close-btn {
  position: absolute;
  top: 5px;
  left: 5px;
  background: white;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.25);
}

.textarea-container {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 30px 0;
}

.custom-textarea {
  width: 100%;
  resize: none;
}

.image-upload-container {
  display: flex;
}

.uploaded-images-container {
  width: 75%;
  position: relative;
  overflow: hidden;
}

.uploaded-image {
  width: 29.615861214%;
  height: 110px;
  cursor: pointer;
  box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.25);
  transition: background 0.2s ease-in-out, opacity 0.2s ease-in-out;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  float: left;
  margin-top: 1.858736059%;
  margin-right: 1.858736059%;
  position: relative;
  border-radius: 4px;
}

.upload-control-container {
  width: 25%;
  padding: 1.3%;
  padding-right: unset;
}

.upload-button {
  font-size: 50px;
  width: 100%;
  height: 110px;
  border: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.disabled-input {
  color: rgba(128, 128, 128, 0.377);
  border: 2px solid rgba(128, 128, 128, 0.377);
}

.image-upload-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.submit-button {
  text-align: center;
  border: 0.5px lightgrey solid;
  margin: 60px 0 0;
  padding: 15px 0;
  border-radius: 20px;
  font-size: 120%;
  box-shadow: 2px 2px 26px 11px rgba(222, 222, 222, 0.41);
  cursor: pointer;
}
