/* LAYOUT */
.top,
.bottom {
  display: flex;
  flex-direction: column;
}

/* SWIPER */
.swiperContainer :global(.swiper-slide) {
}

.swiperContainer :global(.swiper-slide) {
  text-align: center;
  height: calc(9vh - 30px);
  line-height: calc(9vh - 30px);
  font-family: "Avenir Next Cyr Medium", sans-serif !important;
}

.swiperContainer :global(.swiper-slide div) {
  display: inline-block;
  line-height: calc(9vh - 30px);
  height: calc(9vh - 30px);
  /* background: white !important; */
  padding: 0;
  border-radius: 10px;
  width: 100%;
  font-size: 2.2vh;
  box-shadow: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 33px;
}

.button_width {
  width: 97.5% !important;
  text-decoration: none;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  min-height: 36px;
  align-items: center;
  box-shadow: 0px 0px 18px -11px rgb(66 68 90) !important;
}

@media (max-width: 800px) {
  /* .swiperContainer :global(.swiper-slide div) {
    width: 95%;
  } */
  .button_width {
    width: 95% !important;
  }
}

@media (min-height: 900px) {
  /* .swiperContainer :global(.swiper-slide div) {
    width: 95%;
  } */
  .button_width {
    width: 95% !important;
  }
}

.swiperContainer :global(.swiper-horizontal) {
  height: 13vh;
  padding: 15px 0 15px 0;
}

.myshadow,
:global(.myshadow) {
  -webkit-box-shadow: 0px 0px 18px -11px rgb(66 68 90);
  -moz-box-shadow: 0px 0px 18px -11px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 18px -11px rgb(66 68 90);
}

/* CHARTS */
.chartsContainer {
  margin-left: 2.5% !important;
  margin-right: 2.5% !important;
  border-radius: 10px;
}

.chartsContainer > canvas {
  position: absolute;
}

.bigIndexBoxContainer {
  margin-left: 2.5%;
  margin-right: 2.5%;
  display: flex;
  margin-top: 9vh;
  flex-direction: row;
}

.bigIndexBoxContainer > *:first-child {
  margin-right: 2.5%;
}

.barIndicatorContainer {
  margin-left: 2.5%;
  margin-right: 2.5%;
  border-radius: 10px;
  /* margin-top: 1.1vh; */
  margin-bottom: 1.1vh;
  align-items: center;
  display: flex;
  flex: 1;
  height: 11%; /* Divide the height into 7 child containers */
}

.barIndicatorContainer:last-child {
  /* margin-bottom: 15px; */
}

@media (max-width: 426px) {
  .swiperContainer :global(.swiper-slide div) {
    font-size: 1.7vh;
  }
}

@media (min-height: 1599px) {
  .bigIndexBoxContainer {
    height: 12% !important;
  }
}

@media (max-width: 800px) {
  /* .swiperContainer :global(.swiper-slide div) {
    width: 95%;
  } */
  /* .margin__header {
    margin-left: 2.5%;
    margin-right: 2.5%;
  } */
}

@media (max-height: 630px) {
  .bigIndexBoxContainer {
    margin-top: 0vh;
  }
}

@media (max-height: 600px) {
  .swiperContainer :global(.swiper-slide div) {
    font-size: 3.2vh;
  }
}

@media (max-height: 520px) {
  .swiperContainer :global(.swiper-slide div) {
    font-size: 3.8vh;
  }
}

@media (max-height: 430px) {
  .swiperContainer :global(.swiper-slide div) {
    font-size: 4.2vh;
  }
}

@media (max-height: 400px) {
  .swiperContainer :global(.swiper-slide div) {
    font-size: 5vh;
  }
}

@media (max-height: 300px) {
  .swiperContainer :global(.swiper-slide div) {
    font-size: 6vh;
  }
}

.swiperActionButton:hover,
.swiperActionButton:active {
  opacity: 0.9;
  color: rgba(51, 54, 237, 1);
  cursor: pointer;
}

.swiperActionButton {
}
