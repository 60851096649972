.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.phoneInputContainer {
  background: transparent;
}

.MuiFormControl-root.MuiTextField-root {
  width: 100%;
}

.react-tel-input .form-control,
.phoneInputContainer {
  height: 40px !important;
}

.container_ > * {
  margin: 40px 0;
}
.codeInputContainer {
  display: flex;
  justify-content: center;
}

.codeInputContainer input {
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  /* margin-right: 12px; */
  text-transform: uppercase;
  color: #494949;
  font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  background: #fff;
  background-clip: padding-box;
  margin-left: 10px;
}
