.image-preview {
  background: white !important;
  display: flex;
  /* flex-direction: row-reverse; */
}

.custom-file-container {
  display: flex;
  flex-direction: row-reverse;
}

.custom-file-container .image-preview-item {
  height: unset !important;
  margin: unset !important;
  margin-right: 14px !important;
  padding: unset !important;
}

.custom-file-container .label-container {
  display: none;
}

.custom-file-container .input-hidden {
  min-width: unset !important;
}
span.browse-button {
  display: none !important;
}

/* 

.custom-file-container .input-container
{
    display: flex;
    height: unset;
    margin-bottom: 0px;
} */

.custom-file-container .input-container {
  display: flex;
  height: unset !important;
  margin-bottom: 0;
  background-color: rgba(255, 255, 255, 1);
  background-image: url(https://www.freepnglogos.com/uploads/plus-icon/plus-icon-plus-math-icon-download-icons-9.png);
  background-size: 35%;
  background-position: center center;
  background-repeat: no-repeat;
  border: 0.5px #979797 solid;
  width: 25%;
}

/* .input-container:nth-child(n+4), .image-preview:nth-child(n+4) */
.input-container:nth-child(n + 5),
.image-preview:nth-child(n + 5) {
  display: none;
}

.custom-file-container .image-preview {
  height: unset;
  width: 75%;
}

.custom-file-container .input-visible {
  background-color: #fff;
  left: 0;
  line-height: 0;
  padding: 0;
  border: 0;
  color: white;
  position: absolute;
  opacity: 0;
}

.blocker {
  position: absolute;
  right: 0;
  top: 0;
  background: white;
  height: 20px;
  width: 25%;
  z-index: 99;
  height: 100%;
  /* border: 0.5px grey solid; */
  /* margin-right: 10px; */
  /* background-color: rgba(255, 255, 255, 1);
        background-image: url(https://www.freepnglogos.com/uploads/plus-icon/plus-icon-plus-math-icon-download-icons-9.png);
        background-size: 15%;
        background-position: center center;
        background-repeat: no-repeat; */
  /* border: 0.5px grey solid; */
  width: 25%;
  /* padding: 5px 0 5px 5px; */
}

.blocker > * {
  width: 100%;
  z-index: 99;
  height: 100%;
  border: 0.5px grey solid;
  margin-right: 10px;
  background-color: rgba(255, 255, 255, 1);
  background-image: url(https://www.freepnglogos.com/uploads/plus-icon/plus-icon-plus-math-icon-download-icons-9.png);
  background-size: 35%;
  background-position: center center;
  background-repeat: no-repeat;
  border: 0.5px grey solid;
  opacity: 0.1;
}
