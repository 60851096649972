.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 5px 30px 5px 0px;
  flex: 1;
}

.left {
  display: flex;
  font-size: 25px;
  padding: 0 20px;
  width: 20%;
  justify-content: center;
}
.right {
  display: flex;
  flex-direction: column;
  font-size: 2.2vh;
  flex: 1;
  width: 31vw;
}

.right > span {
  /* bar */
  margin: 6px 0;
}

.mod_fontSize_for_temperature {
  font-size: 1.4vh !important;
}

@media (max-width: 1060px) {
  .left {
    font-size: 20px;
  }
}

@media (max-width: 905px) {
  .left {
    font-size: 22px;
  }
}

@media (max-width: 460px) {
  .mod_fontSize_for_temperature {
    font-size: 1.4vh !important;
  }
}

@media (max-width: 426px) {
  .right {
    display: flex;
    flex-direction: column;
    font-size: 1.4vh;
    flex: 1;
  }

  .right > span {
    /* bar */
    margin: 5px 0;
  }

  .left {
    font-size: 20px;
  }
}

@media (min-height: 2400px) {
  .right {
    font-size: 6vh;
  }
  .left {
    font-size: 2vh !important;
  }
}

@media (min-height: 1500px) {
  .right {
    font-size: 3vh;
  }
  .left {
    font-size: 29px;
  }
}

@media (min-height: 1200px) {
  .right {
    font-size: unset;
  }
}

@media (max-height: 560px) {
  .right {
    font-size: 2.4vh;
  }
}

@media (max-height: 470px) {
  .right {
    font-size: 3vh;
  }
}

@media (max-height: 376px) {
  .right {
    font-size: 3.6vh;
  }
}
